// 订单查询
const detail = require(`@/lib/data-service/flight/flight_domestic_buyer_manage_order_detail`);
import LogList from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_log_list";
import refundOrderDetail from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_refundDetail";
import changeOrderDetail from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_changeDetail";
import refundLog from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_refundLog";
import changeLog from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_order_change_log_list";
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue'
//import RefundInfo from "@/page/distributor/air-ticket/components/refundOrderInfo/index.vue";
export default {
    data() {
        return {
            detailData: null,
            loading: true,
            orderNo: "",
            orderStatus: "",
            orderStatusText: "",
            flightType: "1",
            passengers: [],
            activeName: "first",
            tableData: [],
            relaxPayDetailResponse: null,
            orderType: null,
            logListForm: {
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            defaultText: "-----"
        }
    },
    components: {RefundChangeLabel},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.ordersNo;
        this.orderType = this.$route.query.orderType || "";
        let psgNo = this.$route.query.psgNo || "";
        if (psgNo === '') {
            this.psgNo = psgNo
        } else {
            this.psgNo = JSON.parse(psgNo)
        }
        let val = this.GetdetailData();
        this.getLogList(this.orderNo);
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        back() {
            this.$router.go(-1)
        },
        handleSizeChange(val) {
            this.logListForm.pageSize = val;
            this.getLogList(this.detailData);
        },
        handleCurrentChange(val) {
            this.logListForm.currentPage = val;
            this.getLogList(this.detailData);
        },
        GetdetailData() {
            this.detailData = null;
            if (!this.orderType) {
                return detail({ orderNo: this.orderNo })
                    .then(res => {
                        this.detailData = res.orderDetail;
                        this.relaxPayDetailResponse = res.relaxPayDetailResponse
                        this.detailData.fullReductionSnapshot
                            ? (this.detailData.fullReductionSnapshot = JSON.parse(
                            this.detailData.fullReductionSnapshot
                            ))
                            : "";
                        this.loading = false;
                        return res.orderDetail;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else if (this.orderType == "change") {
                return changeOrderDetail({
                    chaOrderNo: this.orderNo,
                    psgNoList: this.psgNo
                })
                    .then(res => {
                        this.detailData = res.detailResult;
                        this.detailData.img = res.detailResult.url.split(",")[0];
                        this.detailData.imgList = res.detailResult.url.split(",");
                        this.detailData.fullReductionSnapshot
                            ? (this.detailData.fullReductionSnapshot = JSON.parse(
                            this.detailData.fullReductionSnapshot
                            ))
                            : "";
                        this.loading = false;
                        return res.changeDetailRes;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                return refundOrderDetail({ refOrderNo: this.orderNo })
                    .then(res => {
                        this.detailData = res.refundDetailRes;
                        this.detailData.img = res.refundDetailRes.url[0];
                        this.detailData.imgList = res.refundDetailRes.url;
                        this.detailData.fullReductionSnapshot
                            ? (this.detailData.fullReductionSnapshot = JSON.parse(
                            this.detailData.fullReductionSnapshot
                            ))
                            : "";
                        this.loading = false;
                        return res.refundDetailRes;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
        getLogList(val) {
            let data;
            if (!this.orderType) {
                data = Object.assign({ orderNo: val }, this.logListForm);
                LogList(data).then(res => {
                    this.tableData = res.pageResult.pageData;
                    this.logListForm.totalCount = res.pageResult.totalCount;
                });
            } else if (this.orderType == "change") {
                data = Object.assign({ changeId: val }, this.logListForm);
                changeLog(data).then(res => {
                    // console.log("改签", res);
                    this.tableData = res.pageResult.pageData;
                    this.logListForm.totalCount = res.pageResult.totalCount;
                });
            } else {
                data = Object.assign({ bizOrderNo: val }, this.logListForm);
                refundLog(data).then(res => {
                    // console.log("退票", res);
                    this.tableData = res.pageResult.pageData;
                    this.logListForm.totalCount = res.pageResult.totalCount;
                });
            }
        },
        getDate(val) {
            return this.$moment(val).format("YYYY-MM-DD hh:mm:ss");
        },
        toChange() {
            if (!this.orderType && this.detailData.orderSource === 4) {
                this.$message({
                    type: "warning",
                    message: `订单号${this.orderNo}为线下订单，暂不支持线上申请改签，请联系客服处理。`,
                    offset: 80
                })
                return
            }
            this.$router.push({
                name:'air-ticket-admin-ticketChanging',
                query:{
                    ordersNo:this.orderNo,
                    psgType:this.$route.query.psgType
                }
            })
        },
        toRefund() {
            if (!this.orderType && this.detailData.orderSource === 4) {
                this.$message({
                    type: "warning",
                    message: `订单号${this.orderNo}为线下订单，暂不支持线上申请退票，请联系客服处理。`,
                    offset: 80
                })
                return
            }
            this.$router.push({
                name:'air-ticket-admin-dishonour',
                query:{
                    ordersNo:this.orderNo,
                    psgType:this.$route.query.psgType
                }
            })
        },
        toRefundAgain() {
            console.log('detailData',this.detailData)
            this.$router.push({
                name: 'air-ticket-admin-react-refund',
                query: {
                    orderNo: this.detailData.orderNo,
                    refOrderNo: this.detailData.refundOderNo,
                    psgType:this.$route.query.psgType
                }
            })
            // this.$router.push({
            //     name:'air-ticket-admin-dishonour',
            //     query:{
            //         ordersNo:this.detailData.orderNo,
            //         psgType:this.$route.query.psgType
            //     }
            // })
        },
        goPage(val) {
            if (!val) return;
            this.$router.push({
                name: "air-ticket-admin-international-list-detail",
                query: { ordersNo: val }
            });
        },
        toOrderDetail() {
            this.$router.push({
                name:'air-ticket-admin-airDetails',
                query:{
                    ordersNo: this.detailData.orderNo,
                    psgType: 1
                }
            })
        },
    },
    beforeRouteLeave (to, from, next) {
        // 导航离开该组件的对应路由时调用
        // 可以访问组件实例 `this`
        console.log(to, from)
        next()
        /*if (to.name === 'air-ticket-admin-purchase-order-list') {
          next()
        } else {
          next({name: 'air-ticket-admin-purchase-order-list'})
        }*/
    }
}